<template>
	<div class="drive_type">
		<van-nav-bar
		class="nav_bar_color"
		title="试驾车型设置"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		>
	</van-nav-bar>
		
		<div :style="{'top':46 + 'px'}" class="drive_type_top">
			勾选的车型会出现在试乘试驾的车型选择列表中
		</div>
		
		<div style="height: 60px">
			
		</div>
		
		<div class="drive_type_list" v-for="v,index in invite_list" :key="index">
			<div class="drive_type_list_title">
				{{v.name}}
			</div>
			
			<!-- 车型 -->
			<div class="" v-for="v2,index2 in v.scsj_car_collocation" :key="index2">
				<div class="drive_models_list" @click="onSelected(index,v2.id)">
				<img v-if="car_model_checked[index].scsj_car_collocation_ids.indexOf(v2.id) != -1" class="drive_models_list_select" src="@/assets/drivroute/icon_xuandui.png" mode="">
				<img v-else class="drive_models_list_select" src="@/assets/drivroute/icon_weixuanz.png" mode="">
				<span class="drive_models_list_name">{{v2.name}}</span>
				</div>
				
				<!-- 车牌 -->
				<div class="drive_license_list" v-for="v3,index3 in v2.license" :key="index3">
					<div :class="{'active':(index3 + 1) == v2.license.length,'drive_license_list_concat':true}" @click="shutdown(v3)">
						<span>{{v3.name}}</span>
						<img v-if="v3.status == 1" src="@/assets/drivroute/icon_xianshi.png" mode="">
						<img v-if="v3.status == 0" src="@/assets/drivroute/icon_yongcnag.png" mode="">
					</div>
				</div>
			</div>
		</div>
		
		
		<!-- 底部 -->
		<div class="user_footer">
			<div class="user_footer_add" @click="save">
				<div class="user_save">
					保存设置
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'vant';
	export default {
		data() {
			return {
				invite_list:[],
				car_model_checked:[],
			}
		},
		mounted(){
			this.get_invite_list()
		},
		methods:{
			async save(){
				let list = []
				this.car_model_checked.forEach(item => {
					console.log(item.scsj_car_collocation_ids.length)
					if(item.scsj_car_collocation_ids.length != 0){
						item.scsj_car_collocation_ids = item.scsj_car_collocation_ids.join(',')
						list.push(item)
					}
					console.log(list)
				})
				const aaa = JSON.stringify(list)
				const {data:res} = await this.$http.post('/api/scsj/edit_store_car_model',{car_model_checked:aaa,store_id:0})
				if(res.code == 1){
					this.$toast.success('保存成功')
					this.$router.go(-1);
				}else{
					this.$toast.fail(res.msg)
				}
			},
			// 车牌隐藏
			 shutdown(v){
				if(v!=1) return ''
				Dialog.confirm({
					title: '车牌',
					message: v.status == 1?'确定隐藏该车牌吗？':'确定显示该车牌吗？',
				}).then(async () => {
					const {data:res} = await this.$http.post('/api/scsj/show_hide_car_license',{license_id:v.id,show:v.status == 1?'0':'1'})
					if(res.code == 1){
						v.status == 0?v.status = 1:v.status = 0
						}
				}).catch(() => {
					// on cancel
				});
				
			},
			// 车型选中
			onSelected(indexs,id){
				const item = this.car_model_checked[indexs]
				const index = item.scsj_car_collocation_ids.indexOf(id)
				if(index == -1){
					// this.$set(item.scsj_car_collocation_ids,item.scsj_car_collocation_ids.length)
					item.scsj_car_collocation_ids.push(id)
				}else{
					item.scsj_car_collocation_ids.splice(index,1)
				}
			},
			// 获取数据
			async get_invite_list(){
				const {data:res} = await this.$http.post('/api/scsj/get_store_car_model_1_6')
				if(res.code == 1){
					this.invite_list = res.data.res.car_model
					this.invite_list = this.invite_list.sort((a,b) => a.id-b.id)
					
					if(res.data.res.car_model_checked != null){
						this.car_model_checked = res.data.res.car_model.map(item => {
							item.scsj_car_collocation_ids = ''
							res.data.res.car_model_checked.forEach(v =>{
								if(v.id == item.id){
									item.scsj_car_collocation_ids = v.scsj_car_collocation_ids
								}
							})
							return {id:item.id,name:item.name,scsj_car_collocation_ids:item.scsj_car_collocation_ids==''?[]: item.scsj_car_collocation_ids.split(',').map(item2 => parseInt(item2))}
						})
						this.car_model_checked = this.car_model_checked.sort((a,b) => a.id-b.id)
					}else{
						this.car_model_checked = res.data.res.car_model.map(item => {
							return {id:item.id,name:item.name,scsj_car_collocation_ids:[]}
						})
					}
					
				}
			},
		}
	}
</script>

<style lang="scss">
.drive_type{
	padding: 0 0 170px;
	.drive_type_top{
		background-color: rgba(25, 25, 25, 1);
		position: fixed;
		text-align: center;
		padding: 40px 32px;
		font-size: 32px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #F3C846;
		line-height: 34px;
	}
	.drive_type_list{
		padding: 0 0 20px;
		margin: 40px 32px;
		border-bottom: 1px solid #ffffff;
		color: #FFFFFF;
		.drive_type_list_title{
			font-size: 40px;
			font-family: Regular;
			font-weight: 400;
			text-align: left;
			margin:20px 0 30px;
		}
		.drive_models_list{
			padding: 16px 0;
			display: flex;
			align-items: center;
			.drive_models_list_select{
				width: 36px;
				height: 36px;
			}
			.drive_models_list_name{
				margin-left: 20px;
				font-size: 36px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
		
		.drive_license_list{
			background: #222222;
			border-radius: 12px;
			padding: 0 28px;
			.drive_license_list_concat{
				padding: 5px 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-bottom: 1px solid rgba(255, 255, 255, 0.2);
				img{
					width: 100px;
					height: 80px;
				}
				span{
					font-size: 32px;
					font-family: HYQiHeiY3-55;
					font-weight: normal;
					color: #FFFFFF;
					
				}
			}
		}
		
	}
}


.user_footer{
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 182px;
	background: #000000;
	text-align: center;
	.user_footer_add{
		width: 670px;
		background: #FA0037;
		text-align: center;
		margin: 30px auto 0;
		line-height: 60px;
		font-size: 36px;
		font-family: HYQiHeiY3-65;
		font-weight: normal;
		color: #FFFFFF;
		.user_save{
			height: 100px;
			text-align: center;
			font-size: 36px;
			font-family: HYQiHeiY3-55;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 100px;
		}
		.user_footer_concat{
			padding-bottom: 20px;
			font-size: 28px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #F4F4F4;
			line-height: 32px;
			
		}
	}
}

.active{
	border-bottom: none !important;
}

</style>
